import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authenticationPage/AuthLogin')));
const AuthRegister = Loadable(lazy(() => import('views/authenticationPage/AuthRegister')));
const AuthForgetPW = Loadable(lazy(() => import('views/authenticationPage/AuthForgetPW')));
const AuthResetPW = Loadable(lazy(() => import('views/authenticationPage/AuthResetPW')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'forget_pw',
      element: <AuthForgetPW />
    },
    {
      path: 'reset_pw',
      element: <AuthResetPW />
    }
  ]
};

export default AuthenticationRoutes;
