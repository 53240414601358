import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';
import cranberrySpeedData from '../../../../../../Dataset_Cranberry/spd.json';
import tsmoSpeedData from '../../../../../../Dataset_TSMO/spd.json';
import sfSpeedData from '../../../../../../Dataset_SF/spd.json';

const prepareChartData = (data, tmcId, mapCenter) => {
  let chartData;

  // If mapCenter is 'sf', exclude the interval columns
  if (mapCenter === 'sf' || mapCenter === 'tsmo') {
    chartData = [['Time', 'Observed', 'Prediction']];
  } else {
    chartData = [
      [
        'Time',
        'Observed',
        'Prediction',
        { type: 'number', role: 'interval' },
        { type: 'number', role: 'interval' },
        { type: 'number', role: 'interval' },
        { type: 'number', role: 'interval' }
      ]
    ];
  }

  const tmcData = data[tmcId];
  if (!tmcData) return chartData; // Handle case where tmcId is not found

  const allKeys = new Set([
    ...Object.keys(tmcData.pre),
    ...Object.keys(tmcData.pro),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['05']) : []),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['25']) : []),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['75']) : []),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['95']) : [])
  ]);

  const sortedKeys = Array.from(allKeys).sort((a, b) => parseInt(a) - parseInt(b));

  sortedKeys.forEach((time) => {
    if (mapCenter === 'sf' || mapCenter === 'tsmo') {
      // Push data without intervals for 'sf'
      chartData.push([`${time}`, parseFloat(tmcData.pre[time]), parseFloat(tmcData.pro[time])]);
    } else {
      // Push data with intervals for other map centers
      chartData.push([
        `${time}`,
        parseFloat(tmcData.pre[time]),
        parseFloat(tmcData.pro[time]),
        parseFloat(tmcData['05'][time]),
        parseFloat(tmcData['25'][time]),
        parseFloat(tmcData['75'][time]),
        parseFloat(tmcData['95'][time])
      ]);
    }
  });

  return chartData;
};

export default function SpeedChart() {
  const { selectedTmcId, mapCenter } = useSelector((state) => state.map);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let tmcId = selectedTmcId;

    // Conditionally select speed data based on mapCenter
    const speedData = mapCenter === 'tsmo' ? tsmoSpeedData : mapCenter === 'sf' ? sfSpeedData : cranberrySpeedData;

    // Use default TMC ID if none is selected or if the selected TMC ID is not found
    if (!speedData[tmcId]) {
      tmcId = mapCenter === 'tsmo' ? '110+05026' : mapCenter === 'sf' ? '102+05805' : '104+04542';
    }

    setChartData(prepareChartData(speedData, tmcId, mapCenter));
  }, [selectedTmcId, mapCenter]);

  return (
    <>
      {chartData.length > 1 ? (
        <Chart
          key={selectedTmcId} // Unique key to force re-render on TMC ID change
          width={'100%'}
          height={'400px'}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            title: `Speed Prediction for TMC ${selectedTmcId || 'N/A'}`,
            hAxis: { title: 'Time (Min)' },
            vAxis: { title: 'Speed (Mph)' },
            legend: { position: 'bottom' },
            series: {
              0: { color: 'red' }, // Observed data
              1: { color: '#0077b6' } // Predicted data
            },
            intervals: { style: 'area', color: 'series-color' }, // Use the color of the series
            interval: {
              0: { style: 'bars', color: '#333' }, // Optional styling for intervals
              1: { style: 'bars', color: '#333' }
            }
          }}
        />
      ) : (
        <div>Loading Chart...</div> // Loading state
      )}
    </>
  );
}
