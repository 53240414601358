import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const MapPage = Loadable(lazy(() => import('views/mapPage')));
const DocumentationPage = Loadable(lazy(() => import('views/documentationPage')));
const UserguidePage = Loadable(lazy(() => import('views/documentationPage/Userguide')));
const InteraticeVisualPage = Loadable(lazy(() => import('views/documentationPage/DynamicDashboard/InteraticeVisualPage')));
const RealTimeUpdatePage = Loadable(lazy(() => import('views/documentationPage/DynamicDashboard/RealTimeUpdatePage')));
const AlertsPage = Loadable(lazy(() => import('views/documentationPage/Alerts')));
const ReportPage = Loadable(lazy(() => import('views/reportPage')));
const PerformancePage = Loadable(lazy(() => import('views/performancePage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '',
      skipLazyLoad: true,
      element: <Navigate to="login" />
    },
    {
      path: 'map',
      element: <MapPage />
    },
    {
      path: 'document',
      element: <DocumentationPage />,
      children: [
        {
          path: 'userguide',
          element: <UserguidePage />
        },
        {
          path: 'dynamic-dashboard',
          children: [
            {
              path: 'interactive-visualizations',
              element: <InteraticeVisualPage />
            },
            {
              path: 'real-time-updates',
              element: <RealTimeUpdatePage />
            }
          ]
        },
        {
          path: 'alerts',
          element: <AlertsPage />
        }
      ]
    },
    {
      path: 'reports',
      element: <ReportPage />
    },
    {
      path: 'performance',
      element: <PerformancePage />
    }
  ]
};

export default MainRoutes;
