import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import mapReducer from './mapReducer';
import userReducer from './userReducer';
import notificationReducer from './notificationReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  map: mapReducer,
  user: userReducer,
  notification: notificationReducer
});

export default reducer;
