// action - state management
import * as actionTypes from './actions';

export const initialState = {
  mapCenter: 'tsmo',
  displayOptions: {
    incidentBlocks: true,
    rcrsIncidents: false,
    mdotIncidents: true,
    fdotIncidents: false,
    wazeAlerts: true
  },
  forecastingHorizon: 0,
  selectedTmcId: '110+05026'
};

// ==============================|| MAP REDUCER ||============================== //

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MAP_CENTER:
      return { ...state, mapCenter: action.payload };
    case actionTypes.SET_DISPLAY_OPTIONS:
      return { ...state, displayOptions: action.payload };
    case actionTypes.SET_FORECASTING_HORIZON:
      return { ...state, forecastingHorizon: action.payload };
    case actionTypes.SET_SELECTED_TMC:
      return { ...state, selectedTmcId: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
