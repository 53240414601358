import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  TextField,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Box,
  CircularProgress
} from '@mui/material';
import IconDelete from '@mui/icons-material/Delete';
import IconAdd from '@mui/icons-material/Add';
import IconClose from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../../../../store/actions'; // Redux action
import { uploadUserDataToS3, getUserDataFromS3 } from '../../../../../views/utilities/s3'; // S3 functions
import * as Yup from 'yup'; // Yup for validation
import { useFormik } from 'formik'; // Formik for managing form state

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string().email('Invalid email format')).max(3, 'You can only enter up to 3 emails'),
  phoneNumbers: Yup.array()
    .of(Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'))
    .max(3, 'You can only enter up to 3 phone numbers')
});

const UserSettingsDialog = ({ open, handleClose, userData }) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [userDetails, setUserDetails] = useState(userData); // Local state for user data

  const dispatch = useDispatch(); // Redux dispatch

  // Fetch user data from S3 when dialog opens
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userData && userData.account) {
          const s3Data = await getUserDataFromS3(userData.account); // Fetch from S3
          setUserDetails(s3Data); // Store in local state
          formik.setFieldValue('phoneNumbers', s3Data.phoneNumbers || ['']);
          formik.setFieldValue('emails', s3Data.emails || ['']);
        }
      } catch (err) {
        console.error('Error fetching user data from S3:', err);
      } finally {
        setLoading(false); // Disable loading spinner after fetching
      }
    };

    if (open) {
      setLoading(true); // Show loading spinner while fetching data
      fetchData();
    }
  }, [open, userData]);

  const formik = useFormik({
    initialValues: {
      phoneNumbers: userDetails.phoneNumbers || [''],
      emails: userDetails.emails || ['']
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const updatedUserData = {
        ...userDetails,
        phoneNumbers: values.phoneNumbers,
        emails: values.emails
      };

      // Dispatch updated data to Redux
      dispatch(updateUserData(updatedUserData));

      // Upload updated user data to S3
      uploadUserDataToS3(updatedUserData);

      handleClose(); // Close the dialog
    }
  });

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle adding/removing phone numbers and emails
  const addPhoneNumber = () => {
    if (formik.values.phoneNumbers.length < 3) {
      formik.setFieldValue('phoneNumbers', [...formik.values.phoneNumbers, '']);
    }
  };

  const removePhoneNumber = (index) => {
    const updatedPhones = formik.values.phoneNumbers.filter((_, i) => i !== index);
    formik.setFieldValue('phoneNumbers', updatedPhones);
  };

  const addEmail = () => {
    if (formik.values.emails.length < 3) {
      formik.setFieldValue('emails', [...formik.values.emails, '']);
    }
  };

  const removeEmail = (index) => {
    const updatedEmails = formik.values.emails.filter((_, i) => i !== index);
    formik.setFieldValue('emails', updatedEmails);
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 3 }}>
          <Typography variant="h4">Settings</Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <IconClose />
          </IconButton>
        </DialogTitle>

        <Box sx={{ display: 'flex', height: '70vh' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              width: '200px',
              minWidth: '200px',
              pt: 2,
              pl: 2,
              pr: 2
            }}
          >
            <Tab label="Profile" />
            <Tab label="Notifications" />
          </Tabs>

          <DialogContent
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 3
            }}
          >
            {/* Profile Tab */}
            {value === 0 && (
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Profile
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Account" value={userDetails.email || ''} disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      value={userDetails.fname || ''}
                      onChange={(e) => setUserDetails({ ...userDetails, fname: e.target.value })} // Update first name in state
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      value={userDetails.lname || ''}
                      onChange={(e) => setUserDetails({ ...userDetails, lname: e.target.value })} // Update last name in state
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      value={userDetails.phone || ''}
                      onChange={(e) => setUserDetails({ ...userDetails, phone: e.target.value })} // Update phone in state
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Notifications Tab */}
            {value === 1 && (
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Notification Settings
                </Typography>

                {/* Phone Numbers Section */}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Phone Numbers
                </Typography>
                {formik.values.phoneNumbers.map((phone, index) => (
                  <Grid container spacing={1} key={index} sx={{ mt: 1 }}>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        sx={{ height: 65 }}
                        label={`Phone ${index + 1}`}
                        value={phone}
                        onChange={(e) => formik.setFieldValue(`phoneNumbers[${index}]`, e.target.value)}
                        error={Boolean(formik.errors.phoneNumbers && formik.errors.phoneNumbers[index])}
                        helperText={formik.errors.phoneNumbers && formik.errors.phoneNumbers[index]}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removePhoneNumber(index)} disabled={formik.values.phoneNumbers.length === 1}>
                        <IconDelete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                {formik.values.phoneNumbers.length < 3 && (
                  <Button onClick={addPhoneNumber} startIcon={<IconAdd />} sx={{ mt: 1 }}>
                    Add Phone Number
                  </Button>
                )}

                {/* Email Addresses Section */}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Email Addresses
                </Typography>
                {formik.values.emails.map((email, index) => (
                  <Grid container spacing={1} key={index} sx={{ mt: 1 }}>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        sx={{ height: 65 }}
                        label={`Email ${index + 1}`}
                        value={email}
                        onChange={(e) => formik.setFieldValue(`emails[${index}]`, e.target.value)}
                        error={Boolean(formik.errors.emails && formik.errors.emails[index])}
                        helperText={formik.errors.emails && formik.errors.emails[index]}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeEmail(index)} disabled={formik.values.emails.length === 1}>
                        <IconDelete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                {formik.values.emails.length < 3 && (
                  <Button onClick={addEmail} startIcon={<IconAdd />} sx={{ mt: 1 }}>
                    Add Email Address
                  </Button>
                )}
              </Box>
            )}
          </DialogContent>
        </Box>

        <DialogActions sx={{ paddingRight: 3, paddingBottom: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserSettingsDialog;
