import React, { useState, useEffect, useRef } from 'react';
import { Box, Slider, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import { useSelector, useDispatch } from 'react-redux';
import { setForecastingHorizon } from '../../../../../store/actions';

const MAX = 30;
const MIN = 0;
const STEP = 5;
const marks = [
  {
    value: MIN,
    label: '0 min'
  },
  {
    value: MAX,
    label: '30 min'
  }
];

export default function ForecastingSlider() {
  const dispatch = useDispatch();
  const forecastingHorizon = useSelector((state) => state.map.forecastingHorizon);

  const [isPlaying, setIsPlaying] = useState(false); // State to track if it's playing
  const intervalRef = useRef(null); // Ref to store the interval ID
  const forecastingHorizonRef = useRef(forecastingHorizon); // Ref to store the latest forecastingHorizon value

  // Keep the forecastingHorizonRef updated with the latest Redux value
  useEffect(() => {
    forecastingHorizonRef.current = forecastingHorizon;
  }, [forecastingHorizon]);

  const handleChange = (_, newValue) => {
    dispatch(setForecastingHorizon(newValue));
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      // Pause the slider
      clearInterval(intervalRef.current);
      setIsPlaying(false);
    } else {
      // Play the slider
      setIsPlaying(true);
      intervalRef.current = setInterval(() => {
        const nextValue = (forecastingHorizonRef.current + STEP) % (MAX + STEP);
        dispatch(setForecastingHorizon(nextValue));
      }, 1000); // Change value every 1 second
    }
  };

  // Cleanup interval on unmount
  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ width: '100%', position: 'relative', px: 3 }}>
      {/* Slider Component */}
      <Slider
        marks={marks}
        step={STEP}
        value={forecastingHorizon}
        valueLabelDisplay="auto"
        min={MIN}
        max={MAX}
        onChange={handleChange}
        sx={{ mt: 2 }}
      />
      {/* Play/Pause Icon */}
      <IconButton
        onClick={togglePlayPause}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          marginRight: 3,
          transform: 'translate(50%, -50%)',
          color: '#000'
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
    </Box>
  );
}
