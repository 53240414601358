import * as React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Typography, Tabs, Tab, Box } from '@mui/material';

// components
import Predictions from '../PredictionSection';
import TuneIcon from '@mui/icons-material/Tune';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import Controls from '../ControlSection';

// ==============================|| SIDEBAR MENU LIST ||============================== //

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%', height: '100%' }}
    >
      {value === index && (
        <Box sx={{ width: '100%' }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

function MenuList() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: { md: 'fit-content', lg: '100vh' },
        width: { md: '100vw', lg: '30vw' },
        overflow: 'auto'
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          width: { xs: '2vw', md: '3vw' }, // Tabs take less space on smaller screens
          minWidth: '60px' // Ensures it doesn’t collapse too much
        }}
      >
        <Tab
          sx={{
            width: { xs: '2vw', md: '3vw' },
            display: 'flex',
            justifyContent: 'left',
            minWidth: '60px' // Minimum width for tab to prevent collapsing too much
          }}
          icon={<TroubleshootIcon />}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            width: { xs: '2vw', md: '3vw' },
            display: 'flex',
            justifyContent: 'left',
            minWidth: '60px'
          }}
          icon={<TuneIcon />}
          {...a11yProps(1)}
        />
      </Tabs>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          width: { md: '100%' },
          padding: 2,
          transition: 'width 0.3s ease'
        }}
      >
        <TabPanel value={value} index={0}>
          <Predictions />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Controls />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default MenuList;
