import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotifications } from '../../../../store/actions';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  Badge
} from '@mui/material';

// assets
import { IconBell, IconTrash, IconInfoCircle } from '@tabler/icons-react';
import Transitions from 'ui-component/extended/Transitions';
import format from 'date-fns/format';

const NotificationSection = () => {
  const theme = useTheme();
  const notifications = useSelector((state) => state.notification.notifications);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const clearAllNotifications = () => {
    dispatch(clearNotifications());
  };

  return (
    <>
      <Box sx={{ ml: 2, mr: 3 }}>
        <Badge color="secondary" badgeContent={notifications.length} overlap="circular">
          <Avatar
            variant="rounded"
            sx={{
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <IconBell stroke={1.5} size="1.3rem" />
          </Avatar>
        </Badge>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card elevation={16} sx={{ width: 300 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h4">Notifications</Typography>
                      <IconTrash stroke={1.5} size="1.3rem" onClick={clearAllNotifications} style={{ cursor: 'pointer' }} />
                    </Box>
                  </CardContent>
                  <Divider />
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper'
                    }}
                  >
                    {notifications.length === 0 ? (
                      <Typography variant="body2" align="center" sx={{ p: 2 }}>
                        No notifications available
                      </Typography>
                    ) : (
                      notifications.map((notification, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <IconInfoCircle stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography component="span" variant="subtitle1" fontWeight="bold">
                                {notification.title}
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography component="span" variant="body2" color="textSecondary">
                                  {notification.message}
                                </Typography>
                                <Box sx={{ mt: 1 }}>
                                  <Typography component="span" variant="caption" color="textSecondary" sx={{ mr: 2 }}>
                                    {format(new Date(notification.sentTime), 'EEEE p')}
                                  </Typography>
                                </Box>
                              </>
                            }
                          />
                        </ListItem>
                      ))
                    )}
                  </List>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
