// action - state management
import * as actionTypes from './actions';

export const initialState = {
  notifications: []
};

// ==============================|| MAP REDUCER ||============================== //

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, action.payload] };
    case actionTypes.CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] };
    default:
      return state;
  }
};

export default notificationReducer;
