import { useSelector } from 'react-redux';
import { Typography, Alert } from '@mui/material';
import tsmoAnomalyData from '../../../../../../Dataset_TSMO/anomaly.json';
import sfAnomalyData from '../../../../../../Dataset_SF/anomaly.json';

export default function EarlyAnomalyAlert() {
  const { mapCenter } = useSelector((state) => state.map);

  // Select anomaly data based on the mapCenter
  const anomalyData = mapCenter === 'tsmo' ? tsmoAnomalyData : sfAnomalyData;

  // Extract the anomaly message
  const generateAnomalyMessage = () => {
    const anomalies = [];

    Object.entries(anomalyData).forEach(([tmcId, data]) => {
      // Check for anomalies in the "pro" forecast
      Object.entries(data.pro).forEach(([time, value]) => {
        if (value > 0) {
          anomalies.push(`• TMC ID ${tmcId} detected an anomaly in ${time} minutes.`);
        }
      });
    });

    return anomalies.length > 0
      ? anomalies.join('\n') // Combine messages with line breaks
      : 'No anomalies detected.';
  };

  const anomalyMessage = generateAnomalyMessage();

  return (
    <>
      <Typography component={'span'} variant="h3" sx={{ mb: 2 }}>
        Early Anomaly Alert
      </Typography>

      <Alert severity={anomalyMessage === 'No anomalies detected.' ? 'info' : 'error'} sx={{ whiteSpace: 'pre-line' }}>
        {anomalyMessage}
      </Alert>
    </>
  );
}
