// store/index.js

import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import { createLogger } from 'redux-logger';
import { thunk } from 'redux-thunk';
import reducer from './reducer'; // Make sure this path is correct and the reducers are combined correctly

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'] // Only persist the user reducer (example)
};

const persistedReducer = persistReducer(persistConfig, reducer);

// Create the Redux store with persisted reducer, logger, and thunk middleware
const store = createStore(persistedReducer, applyMiddleware(thunk, createLogger()));

// Create the persistor
const persistor = persistStore(store);

// Export both the store and persistor
export { store, persistor };
