import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { setDisplayOptions } from '../../../../../store/actions';

export default function DisplayCheckBox() {
  const { mapCenter, displayOptions } = useSelector((state) => state.map);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(
      setDisplayOptions({
        ...displayOptions,
        [event.target.name]: event.target.checked
      })
    );
  };

  return (
    <FormGroup>
      {/* Incident Blocks checkbox, common for both Cranberry and TSMO */}
      <FormControlLabel
        control={<Checkbox checked={displayOptions.incidentBlocks} onChange={handleChange} name="incidentBlocks" />}
        label="Incident Blocks"
      />
      {mapCenter === 'cranberry' || mapCenter === 'pittsburgh' || mapCenter === 'tsmo' ? (
        <>
          {/* RCRS Incidents checkbox, only for Cranberry */}
          <FormControlLabel
            control={<Checkbox checked={displayOptions.wazeAlerts} onChange={handleChange} name="wazeAlerts" />}
            label="Waze Alerts"
          />
        </>
      ) : null}
      {/* Conditional rendering based on mapCenter */}
      {mapCenter === 'cranberry' || mapCenter === 'pittsburgh' ? (
        <>
          {/* RCRS Incidents checkbox, only for Cranberry */}
          <FormControlLabel
            control={<Checkbox checked={displayOptions.rcrsIncidents} onChange={handleChange} name="rcrsIncidents" />}
            label="RCRS Incidents"
          />
        </>
      ) : mapCenter === 'tsmo' ? (
        <>
          {/* MDOT Incidents checkbox, only for TSMO */}
          <FormControlLabel
            control={<Checkbox checked={displayOptions.mdotIncidents} onChange={handleChange} name="mdotIncidents" />}
            label="MDOT Incidents"
          />
        </>
      ) : mapCenter === 'sf' ? (
        <>
          {/* MDOT Incidents checkbox, only for TSMO */}
          <FormControlLabel
            control={<Checkbox checked={displayOptions.fdotIncidents} onChange={handleChange} name="fdotIncidents" />}
            label="FDOT Incidents"
          />
        </>
      ) : null}
    </FormGroup>
  );
}
