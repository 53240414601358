import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const NAV_ITEMS = [
  { label: 'Documentation', path: '/document/userguide' },
  { label: 'Map', path: '/map' },
  { label: 'Reports', path: '/reports' },
  { label: 'Performance', path: '/performance' }
];

function NavSection() {
  const location = useLocation();

  // Function to check if the current path matches the button's path
  const isCurrentPage = (path) => location.pathname === path;

  return (
    <Grid container spacing={2}>
      {NAV_ITEMS.map((item) => (
        <Grid item key={item.path}>
          <Button
            component={Link}
            to={item.path}
            variant="text"
            sx={{
              color: 'black',
              backgroundColor: isCurrentPage(item.path) ? '#f0f0f0' : 'transparent',
              '&:hover': { backgroundColor: '#e0e0e0' } // Optional: Add hover styling
            }}
          >
            {item.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default NavSection;
