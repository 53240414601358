import * as React from 'react';
// material-ui
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { setMapCenter, setDisplayOptions } from '../../../../../store/actions';

const scales = [
  {
    value: 'tsmo',
    label: 'TSMO'
  },
  {
    value: 'cranberry',
    label: 'Cranberry Township'
  },
  {
    value: 'pittsburgh',
    label: 'Pittsburgh'
  },
  {
    value: 'sf',
    label: 'South Florida'
  }
];

export default function ScaleSelect() {
  const { mapCenter, displayOptions } = useSelector((state) => state.map);
  const [scaleName, setSacleName] = React.useState(mapCenter);
  const dispatch = useDispatch();

  const updateDisplayOptions = (mapCenter) => {
    if (mapCenter === 'tsmo') {
      dispatch(
        setDisplayOptions({
          ...displayOptions,
          incidentBlocks: true,
          rcrsIncidents: false,
          mdotIncidents: true,
          fdotIncidents: false,
          wazeAlerts: true
        })
      );
    } else if (mapCenter === 'sf') {
      dispatch(
        setDisplayOptions({
          ...displayOptions,
          incidentBlocks: true,
          rcrsIncidents: false,
          mdotIncidents: false,
          fdotIncidents: true,
          wazeAlerts: false
        })
      );
    } else {
      dispatch(
        setDisplayOptions({
          ...displayOptions,
          incidentBlocks: true,
          rcrsIncidents: true,
          mdotIncidents: false,
          fdotIncidents: false,
          wazeAlerts: true
        })
      );
    }
  };

  const handleChange = (event) => {
    dispatch(setMapCenter(event.target.value));
    setSacleName(event.target.value);

    updateDisplayOptions(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">Select Area</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={scaleName} // Set the value to the current scaleName
          displayEmpty
          label="Scale Name"
          onChange={handleChange}
        >
          {scales.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
