import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';
import tsmoAnomalyData from '../../../../../../Dataset_TSMO/anomaly_prob.json';
import sfAnomalyData from '../../../../../../Dataset_SF/anomaly_prob.json';

const prepareChartData = (data, tmcId) => {
  const chartData = [['Time', 'Observed', 'Prediction']];

  const tmcData = data[tmcId];
  if (!tmcData) return chartData; // Handle case where tmcId is not found

  const allKeys = new Set([...Object.keys(tmcData.pre), ...Object.keys(tmcData.pro)]);

  const sortedKeys = Array.from(allKeys).sort((a, b) => parseInt(a) - parseInt(b));

  sortedKeys.forEach((time) => {
    chartData.push([`${time}`, parseFloat(tmcData.pre[time]), parseFloat(tmcData.pro[time])]);
  });

  return chartData;
};

export default function AnomalyProbChart() {
  const { selectedTmcId, mapCenter } = useSelector((state) => state.map);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let tmcId = selectedTmcId;
    // Conditionally select the appropriate anomaly data based on mapCenter
    const anomalyData = mapCenter === 'sf' ? sfAnomalyData : tsmoAnomalyData;

    // Use default TMC ID if none is selected or if the selected TMC ID is not found
    if (!anomalyData[tmcId]) {
      tmcId = mapCenter === 'tsmo' ? '110+05026' : mapCenter === 'sf' ? '102+05805' : '104+04542';
    }

    setChartData(prepareChartData(anomalyData, tmcId));
  }, [selectedTmcId, mapCenter]);

  return (
    <>
      {chartData.length > 1 ? (
        <Chart
          key={selectedTmcId} // Unique key to force re-render on TMC ID change
          width={'100%'}
          height={'400px'}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            title: `Anomaly Prediction for TMC ${selectedTmcId || 'N/A'}`,
            hAxis: { title: 'Time (Min)' },
            vAxis: { title: 'Probability' },
            legend: { position: 'bottom' },
            series: {
              0: { color: 'red' }, // Observed data
              1: { color: '#0077b6' } // Predicted data
            },
            intervals: { style: 'area', color: 'series-color' }, // Use the color of the series
            interval: {
              0: { style: 'bars', color: '#333' }, // Optional styling for intervals
              1: { style: 'bars', color: '#333' }
            }
          }}
        />
      ) : (
        <div>Loading Chart...</div> // Loading state
      )}
    </>
  );
}
