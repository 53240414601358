import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'us-east-2'
});

const s3 = new AWS.S3();

const BUCKET_NAME = 'traffic-dashboard-1';

// Function to get user data from S3 using account (email)
export const getUserDataFromS3 = async (email) => {
  const params = {
    Bucket: BUCKET_NAME,
    Key: `account/${email}.json` // Assumes account email is used as the key in S3
  };

  try {
    const data = await s3.getObject(params).promise();
    return JSON.parse(data.Body.toString('utf-8')); // Return the parsed JSON data
  } catch (err) {
    console.error('Error fetching user data from S3:', err);
    throw new Error('User not found');
  }
};

export const uploadUserDataToS3 = (userData) => {
  const params = {
    Bucket: BUCKET_NAME,
    Key: `account/${userData.email}.json`,
    Body: JSON.stringify(userData),
    ContentType: 'application/json'
  };

  s3.upload(params, (err) => {
    if (err) {
      console.error('Error uploading data: ', err);
      return;
    }
  });
};

// Function to check if a user exists in S3
export const checkUserExistsInS3 = async (email) => {
  const params = {
    Bucket: BUCKET_NAME,
    Key: `account/${email}.json`
  };

  try {
    await s3.headObject(params).promise(); // Check if the object exists
    return true; // User exists
  } catch (err) {
    if (err.code === 'NotFound') {
      return false; // User does not exist
    }
    throw err; // Some other error occurred
  }
};

// Function to update the user's password in S3
export const updateUserPasswordInS3 = async (email, newPassword) => {
  try {
    // Fetch the user data
    const userData = await getUserDataFromS3(email);

    // Update the password field
    userData.password = newPassword;

    // Upload the updated data back to S3
    await uploadUserDataToS3(userData);
    return { message: 'Password updated successfully' };
  } catch (err) {
    console.error('Error updating password in S3:', err);
    throw new Error('Failed to update password');
  }
};
