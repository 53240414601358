import * as React from 'react';
import { Container, Typography, Stack, Grid } from '@mui/material';
import TrafficPrediction from './TrafficPredictionSection';
import RecommendAction from './TrafficPredictionSection/RecommendAction';
import { format } from 'date-fns';
import EarlyAnomalyAlert from './TrafficPredictionSection/EarlyAnomalyAlert';

// Helper function to get the nearest past 5-minute mark
const getNearestPastFiveMinutes = (date) => {
  const minutes = date.getMinutes();
  const nearestFive = minutes - (minutes % 5); // Get the nearest lower multiple of 5
  const adjustedDate = new Date(date);
  adjustedDate.setMinutes(nearestFive);
  adjustedDate.setSeconds(0);
  adjustedDate.setMilliseconds(0); // Set seconds and milliseconds to zero
  return adjustedDate;
};

// Helper function to calculate the delay until the next 5-minute mark
const getDelayUntilNextFiveMinutes = () => {
  const currentTime = new Date();
  const nextFiveMinutes = new Date(currentTime);
  nextFiveMinutes.setMinutes(currentTime.getMinutes() + (5 - (currentTime.getMinutes() % 5)), 0, 0);
  return nextFiveMinutes - currentTime; // Return the delay in milliseconds
};

function Predictions() {
  // State to track the last update time and current time
  const [lastUpdateTime, setLastUpdateTime] = React.useState(getNearestPastFiveMinutes(new Date()));
  const [currentTime, setCurrentTime] = React.useState(new Date());

  // Effect to update the current time every second
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Effect to update the last update time at the next 5-minute mark
  React.useEffect(() => {
    // Calculate the delay until the next 5-minute mark
    const delay = getDelayUntilNextFiveMinutes();

    // Set a timeout to update the lastUpdateTime at the next 5-minute mark
    const timeout = setTimeout(() => {
      setLastUpdateTime(getNearestPastFiveMinutes(new Date()));

      // After the first timeout, set an interval to update every 5 minutes
      const interval = setInterval(() => {
        setLastUpdateTime(getNearestPastFiveMinutes(new Date()));
      }, 300000); // 300,000 milliseconds = 5 minutes

      return () => clearInterval(interval);
    }, delay);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container disableGutters>
      <Stack spacing={2} sx={{ width: { sm: '100%', md: '100%' } }}>
        <Typography component={'span'} variant="h1" color="#0066CC">
          Predictions
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              Current time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              {format(currentTime, 'yyyy-MM-dd HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              Last update
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              {format(lastUpdateTime, 'yyyy-MM-dd HH:mm:ss')}
            </Typography>
          </Grid>
        </Grid>
        <EarlyAnomalyAlert />
        <RecommendAction />
        <TrafficPrediction />
      </Stack>
    </Container>
  );
}

export default Predictions;
