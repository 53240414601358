import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { Typography, Tabs, Tab, Box } from '@mui/material';
import SpeedChart from './SpeedChart';
import CongestionChart from './CongestionRateChart';
import AnomalyChart from './AnomalyChart';
import IncidentChart from './IncidentChart';
import AnomalyProbChart from './AnomalyProbChart';
import IncidentProbChart from './IncidentProbChart';
import { styled } from '@mui/system';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`horizonal-tabpanel-${index}`} aria-labelledby={`horizonal-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

// Styled Tabs Component to allow wrapping if overflow and position underline correctly
const WrappedTabs = styled(Tabs)(() => ({
  '& .MuiTabs-flexContainer': {
    flexWrap: 'wrap' // Allow tabs to wrap into multiple lines
  },
  '& .MuiTab-root': {
    minWidth: '100px',
    flex: '1 0 auto'
  },
  '& .MuiTabs-indicator': {
    bottom: '0' // Ensure the underline (indicator) is positioned directly below the tabs
  }
}));

export default function TrafficPrediction() {
  const { mapCenter } = useSelector((state) => state.map);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Determine which tabs to render based on mapCenter
  const tabs = [{ label: 'Speed', component: <SpeedChart /> }];

  if (mapCenter === 'cranberry' || mapCenter === 'pittsburgh') {
    tabs.push({ label: 'Congestion Rate', component: <CongestionChart /> });
  }

  if (mapCenter === 'tsmo' || mapCenter === 'sf') {
    tabs.push({ label: 'Anomaly', component: <AnomalyChart /> });
    tabs.push({ label: 'Anomaly Probability', component: <AnomalyProbChart /> });
    tabs.push({ label: 'Incident', component: <IncidentChart /> });
    tabs.push({ label: 'Incident Probability', component: <IncidentProbChart /> });
  }

  return (
    <>
      <Typography component={'span'} variant="h3">
        Traffic predictions
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <WrappedTabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </WrappedTabs>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </>
  );
}
