import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, SwipeableDrawer } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      <BrowserView>
        <PerfectScrollbar component="div" style={{ width: matchUpMd ? '30vw' : '100%', height: '100vh' }}>
          <MenuList />
        </PerfectScrollbar>
      </BrowserView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, height: '100vh' }} aria-label="mailbox folders">
      {matchUpMd ? (
        <Drawer
          container={container}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          onClose={drawerToggle}
          sx={{
            '& .MuiDrawer-paper': {
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: 'none',
              [theme.breakpoints.up('md')]: {
                top: '80px'
              }
            }
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {drawer}
        </Drawer>
      ) : (
        <SwipeableDrawer
          container={container}
          variant="persistent"
          anchor="bottom"
          open={drawerOpen}
          onClose={drawerToggle}
          onOpen={drawerToggle}
          disableSwipeToOpen={true}
          sx={{
            '& .MuiDrawer-paper': {
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              height: '50vh',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              overflowY: 'auto'
            }
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          <PerfectScrollbar component="div" style={{ width: '100%', height: '100%' }}>
            <MenuList />
          </PerfectScrollbar>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
